import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Stack } from "@mui/material";
import { Box } from "@mui/system";
import { Breadcrumb, SimpleCard } from "../../../../app/components";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  Radio,
  RadioGroup,
  styled,
} from "@mui/material";
import { Span } from "../../../../app/components/Typography";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));

const TextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));

const Profile = () => {
  const [state, setState] = useState({ date: new Date() });

  useEffect(() => {
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== state.password) return false;

      return true;
    });
    return () => ValidatorForm.removeValidationRule("isPasswordMatch");
  }, [state.password]);

  const handleSubmit = (event) => {
    // console.log("submitted");
    // console.log(event);
  };

  const handleChange = (event) => {
    event.persist();
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleDateChange = (date) => setState({ ...state, date });

  const {
    username,
    firstName,
    creditCard,
    mobile,
    password,
    confirmPassword,
    gender,
    date,
    email,
  } = state;

  return (
    <div>
      <Container>
        <Box className="breadcrumb">
          <Breadcrumb routeSegments={[{ name: "Profile" }]} />
        </Box>

        <Stack spacing={3}>
          <SimpleCard title="Simple Form">
            <ValidatorForm onSubmit={handleSubmit} onError={() => null}>
              <Grid container spacing={6}>
                <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                  <TextField
                    type="text"
                    name="username"
                    id="standard-basic"
                    value={username || ""}
                    onChange={handleChange}
                    errorMessages={["this field is required"]}
                    label="Username (Min length 4, Max length 9)"
                    validators={[
                      "required",
                      "minStringLength: 4",
                      "maxStringLength: 9",
                    ]}
                  />

                  <TextField
                    type="text"
                    name="firstName"
                    label="First Name"
                    onChange={handleChange}
                    value={firstName || ""}
                    validators={["required"]}
                    errorMessages={["this field is required"]}
                  />

                  <TextField
                    type="email"
                    name="email"
                    label="Email"
                    value={email || ""}
                    onChange={handleChange}
                    validators={["required", "isEmail"]}
                    errorMessages={[
                      "this field is required",
                      "email is not valid",
                    ]}
                  />

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={date}
                      onChange={handleDateChange}
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          label="Date picker"
                          id="mui-pickers-date"
                          sx={{ mb: 2, width: "100%" }}
                        />
                      )}
                    />
                  </LocalizationProvider>

                  <TextField
                    sx={{ mb: 4 }}
                    type="number"
                    name="creditCard"
                    label="Credit Card"
                    onChange={handleChange}
                    value={creditCard || ""}
                    errorMessages={["this field is required"]}
                    validators={[
                      "required",
                      "minStringLength:16",
                      "maxStringLength: 16",
                    ]}
                  />
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                  <TextField
                    type="text"
                    name="mobile"
                    value={mobile || ""}
                    label="Mobile Nubmer"
                    onChange={handleChange}
                    validators={["required"]}
                    errorMessages={["this field is required"]}
                  />
                  <TextField
                    name="password"
                    type="password"
                    label="Password"
                    value={password || ""}
                    onChange={handleChange}
                    validators={["required"]}
                    errorMessages={["this field is required"]}
                  />{" "}
                  <TextField
                    type="text"
                    name="firstName"
                    label="Section"
                    onChange={handleChange}
                    value={firstName || ""}
                    validators={["required"]}
                    errorMessages={["this field is required"]}
                  />
                  <TextField
                    type="text"
                    name="firstName"
                    label="Birthday"
                    onChange={handleChange}
                    value={firstName || ""}
                    validators={["required"]}
                    errorMessages={["this field is required"]}
                  />
                  <TextField
                    type="text"
                    name="firstName"
                    label="Address"
                    onChange={handleChange}
                    value={firstName || ""}
                    validators={["required"]}
                    errorMessages={["this field is required"]}
                  />
                  <TextField
                    type="text"
                    name="firstName"
                    label="Id No"
                    onChange={handleChange}
                    value={firstName || ""}
                    validators={["required"]}
                    errorMessages={["this field is required"]}
                  />
                  <TextField
                    type="password"
                    name="confirmPassword"
                    onChange={handleChange}
                    label="Confirm Password"
                    value={confirmPassword || ""}
                    validators={["required", "isPasswordMatch"]}
                    errorMessages={[
                      "this field is required",
                      "password didn't match",
                    ]}
                  />
                  <RadioGroup
                    row
                    name="gender"
                    sx={{ mb: 2 }}
                    value={gender || ""}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Male"
                      label="Male"
                      labelPlacement="end"
                      control={<Radio color="secondary" />}
                    />

                    <FormControlLabel
                      value="Female"
                      label="Female"
                      labelPlacement="end"
                      control={<Radio color="secondary" />}
                    />

                    <FormControlLabel
                      value="Others"
                      label="Others"
                      labelPlacement="end"
                      control={<Radio color="secondary" />}
                    />
                  </RadioGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="I have read and agree to the terms of service."
                  />
                </Grid>
              </Grid>

              <Button color="primary" variant="contained" type="submit">
                <Icon>send</Icon>
                <Span sx={{ pl: 1, textTransform: "capitalize" }}>Submit</Span>
              </Button>
            </ValidatorForm>
          </SimpleCard>
        </Stack>
      </Container>
    </div>
  );
};

export default Profile;
